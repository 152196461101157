import axios from 'axios';

import {BASE_URL_V2} from '../config';

export async function typeSupport() {
  const response = await axios.get(
    `${BASE_URL_V2}types?type=AreaSuporte,TipoSuporteFinanceiro,TipoSuporteRedePrestadores,TipoSuporteOperacoes`,
    {
      auth: {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password'),
      },
    },
  );
  return response.data;
}

export async function getRefuseTypes() {
  const response = await axios.get(`${BASE_URL_V2}types?type=TipoRecusaSolicitacao`, {
    auth: {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    },
  });
  return response.data;
}

export async function typeFailureDafHelper() {
  const response = await axios.get(`${BASE_URL_V2}types?type=TipoDefeitoDAF`, {
    auth: {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    },
  });
  return response.data;
}
