import React, {Component} from 'react';

import Swal from 'sweetalert2';
import {Field, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {detailedDiff} from 'deep-object-diff';

import CustomModal, {onModalClose, doOpenModal, doCloseModal} from '../common/CustomModal';
import {
  hasFechamentoAndPendenciasAccess,
  hasSoonProfileAndLocalizaAccess,
} from '../../utils/check_permission';
import {
  USUARIO_TIPO_BASE,
  USUARIO_TIPO_EMPRESA,
  USUARIO_TIPO_REBOQUEME,
} from '../../reducers/constants';
import {getPriceAlterPrediction} from '../../api/solicitation_helper';
import {toastError, toastSuccess} from '../../actions/toast_actions';
import {updateSolicitaionValues, updateSolicitationPDF} from '../../actions/relatorio_actions';

class EditInfo extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleYardVisit = this.handleYardVisit.bind(this);
    this.state = {
      realPrice: null,
      realPricePartner: null,
      tempoEspera: null,
      valorSaidaCliente: null,
      valorSaidaParceiro: null,
      ComissaoReboqueme: null,
      blindado: null,
      capotado: null,
      patins: null,
      semRodas: null,
      adicionalNoturno: null,
      initialValuesState: null,
      isDataRealPriceAvailable: false,
      realPriceValue: null,
      realPricePartnerValue: null,
    };
  }

  async confirmFileSend(field, value, updateFunction, reloadFunction) {
    const {solicitation} = this.props;
    Swal.fire({
      title: 'Tem certeza que deseja enviar esse arquivo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: 'grey',
      reverseButtons: true,
      confirmButtonText: 'Sim, enviar!',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.value) {
        updateFunction(solicitation.codigo, value, reloadFunction);
      }
    });
  }

  async getRealPrice(isYardVisit = false) {
    const {solicitation} = this.props;
    let newService = solicitation;
    this.setState({isDataRealPriceAvailable: false});

    const auth = {
      login: '',
      password: '',
    };
    if (solicitation.statusSolicitacao === 'Agendada') {
      newService.departureTime = moment(solicitation.dataAgendada, 'DD/MM/YYYY hh:mm').format(
        'YYYY-MM-DD HH:mm:ss',
      );
    }

    newService.tipoServico = solicitation.tipoServico.codigo
      ? solicitation.tipoServico.codigo
      : solicitation.tipoServico;
    newService['company'] = {value: solicitation.empresa.codigo};
    newService.codigoSolicitacao = solicitation.codigo;
    newService.atualizarPreco = true;

    if (isYardVisit) {
      newService.situacaoVeiculo = {
        blindado: false,
        capotado: false,
        garagem: false,
        quantidadeRodasTravadas: 0,
        rodasTravadas: false,
        semRodas: false,
      };
    }

    try {
      const realPrice = await getPriceAlterPrediction(newService, auth);
      this.setState(
        {
          realPrice: realPrice.valor,
          realPricePartner: realPrice.valorParceiro,
          tempoEspera: realPrice.tempoEspera,
          valorSaidaCliente: realPrice.valorSaidaCliente,
          valorSaidaParceiro: realPrice.valorSaidaParceiro,
          ComissaoReboqueme: realPrice.percentualComissaoReboqueme,
          blindado: realPrice.blindado,
          capotado: realPrice.capotado,
          patins: realPrice.patins,
          semRodas: realPrice.semRodas,
          kmExcedenteParceiro: realPrice.kmExcedenteParceiro,
          kmExcedenteCliente: realPrice.kmExcedenteCliente,
          adicionalNoturno: realPrice.adicionalNoturno,
          realPriceValue: realPrice.valor,
          realPricePartnerValue: realPrice.valorParceiro,
        },
        () => doOpenModal('CONTEXT_VER_VALORES'),
      );

      this.setState({isDataRealPriceAvailable: true});
      this.props.hideFooter();
    } catch (error) {
      toastError(error.mensagemCliente);
      return;
    }
  }

  async handleYardVisit(event) {
    event.preventDefault();

    await this.getRealPrice(true);

    const {realPrice, realPricePartner} = this.state;
    const halfServiceValue = parseFloat(realPrice / 2).toFixed(2);
    const halfPartnerValue = parseFloat(realPricePartner / 2).toFixed(2);
    this.props.change('valorServico', halfServiceValue);
    this.props.change('valorServicoParceiro', halfPartnerValue);
    this.props.change('acessorios', 0);
  }

  handleClick() {
    this.getRealPrice();
  }

  componentDidMount() {
    this.setState({initialValuesState: this.props.initialValues});
    onModalClose('CONTEXT_VER_VALORES', this.props.showFooter);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.valuesUpdated) doCloseModal('CONTEXT_EDITINFO');
  }

  handleChange(type, value) {
    this.props.change('valorServicoParceiro', parseFloat(value * 0.8).toFixed(2));
  }
  render() {
    const {
      handleSubmit,
      initialValues,
      updateSolicitaionValues,
      solicitation,
      login,
      afterSolicitationValues,
      updateSolicitationPDF,
      reloadData,
    } = this.props;
    const {valorCorrida, comissaoReboqueme, valorParceiro} = initialValues;

    const {isDataRealPriceAvailable} = this.state;

    const {profiles} = login;

    const permissionRealPrice = [6, 10, 11];
    const canSeeRealPrice =
      login.user_info.usuario.tipoUsuario === 1 &&
      profiles.some((item) => permissionRealPrice.includes(item.id));

    const valorCorridaUsers = [1, 2];

    const userPrivilegiesValorCorrida = valorCorridaUsers.includes(
      login.user_info.usuario.tipoUsuario,
    );

    const baseWithDetailValue =
      solicitation &&
      solicitation.formaPagamento &&
      login.user_info.usuario.tipoUsuario === 6 &&
      solicitation.formaPagamento.codigo === 'DINHEIRO';

    return (
      <div className="alterInfoBody">
        {solicitation && hasSoonProfileAndLocalizaAccess(solicitation.empresa.codigo) && (
          <div>
            {!solicitation.ssPDF ? (
              <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
                <label htmlFor="ssLocaliza" className="labelSS">
                  Atribuição de SS Localiza
                </label>
                <input
                  accept=".pdf"
                  type="file"
                  name="ssPDF"
                  id="ssPDF"
                  onChange={(e) =>
                    this.confirmFileSend(
                      'ssPDF',
                      e.target.files[0],
                      updateSolicitationPDF,
                      reloadData,
                    )
                  }
                  style={{
                    backgroundColor: '#40baff',
                    borderRadius: 3,
                    color: '#fff',
                    cursor: 'pointer',
                    padding: '7px 12px',
                    fontWeight: 'normal',
                    fontSize: 14,
                  }}
                />
              </form>
            ) : (
              <div>
                <label htmlFor="ssLocaliza" className="labelSS">
                  Link SS Localiza
                </label>
                <span>{' - '}</span>
                <a href={solicitation.ssPDF} target="_blank" rel="noreferrer">
                  Acessar PDF
                </a>
              </div>
            )}

            <div className={`row pdfSS ${solicitation.ssPDF ? 'added' : 'notAdded'}`}>
              <span> {solicitation.ssPDF ? 'SS PDF atribuido' : 'SS PDF Não Atribuido'}</span>
            </div>
          </div>
        )}

        <div className="row form-group pull-center relatorio-total">
          {(userPrivilegiesValorCorrida || baseWithDetailValue) && (
            <div className="col-xs-4">
              <label>Total da Corrida</label>
              <span className="relatorio-title font-bold block">
                <small>R$</small>
                {parseFloat(valorCorrida).toFixed(2)}
              </span>
              {hasFechamentoAndPendenciasAccess() && (
                <a onClick={this.handleYardVisit}>Alterar para meia corrida</a>
              )}
            </div>
          )}
          {(login.user_info.usuario.tipoUsuario === 1 || baseWithDetailValue) && (
            <div className="col-xs-4">
              <label className="">Comissão</label>
              <span className="relatorio-title font-bold block">
                <small>R$</small>
                {parseFloat(comissaoReboqueme).toFixed(2)}
              </span>
            </div>
          )}
          {(login.user_info.usuario.tipoUsuario === 1 ||
            login.user_info.usuario.tipoUsuario === 6) && (
            <div className="col-xs-4">
              <label>Total Parceiro</label>
              <span className="relatorio-title font-bold block">
                <small>R$</small>
                {parseFloat(valorParceiro).toFixed(2)}
              </span>
            </div>
          )}
        </div>
        <form>
          <div className="row form-group">
            {(login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
              login.user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA) && (
              <div className="form-group custom-tab col-md-2">
                <label>Serviço</label>
                <Field
                  onChange={this.handleChange}
                  type="number"
                  name="valorServico"
                  component="input"
                  className="form-control big-form"
                />
              </div>
            )}
            {(login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
              login.user_info.usuario.tipoUsuario === USUARIO_TIPO_BASE) && (
              <div className="form-group custom-tab col-md-2">
                <label>Vlr.Parceiro</label>
                <Field
                  type="number"
                  name="valorServicoParceiro"
                  component="input"
                  className="form-control big-form"
                />
              </div>
            )}
            {(login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
              login.user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA) && (
              <div className="form-group custom-tab col-md-2">
                <label>Acessórios</label>
                <Field
                  type="number"
                  name="acessorios"
                  component="input"
                  className="form-control "
                />
              </div>
            )}

            <div className="form-group custom-tab col-md-2">
              <label>Pedágio</label>
              <Field
                type="number"
                name="valorPedagio"
                component="input"
                className="form-control"
              />
            </div>
            {(login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
              login.user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA) && (
              <div className="form-group custom-tab col-md-2">
                <label>H.Parada</label>
                <Field
                  type="number"
                  name="horaParada"
                  component="input"
                  className="form-control"
                />
              </div>
            )}
            {(login.user_info.usuario.tipoUsuario === USUARIO_TIPO_REBOQUEME ||
              login.user_info.usuario.tipoUsuario === USUARIO_TIPO_EMPRESA) && (
              <div className="form-group custom-tab col-md-2">
                <label>H.Trab</label>
                <Field
                  type="number"
                  name="horaTrabalhada"
                  component="input"
                  className="form-control"
                />
              </div>
            )}
          </div>{' '}
          <div>
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                {canSeeRealPrice && (
                  <a onClick={this.handleClick}>Visualizar detalhes e valores reais</a>
                )}
                <div>
                  {hasFechamentoAndPendenciasAccess() && (
                    <button
                      className="btn btn-primary btn-edit-info"
                      onClick={handleSubmit((v) => {
                        const differences = detailedDiff(this.state.initialValuesState, v);
                        const updatedValues = {};

                        for (const key in differences.updated) {
                          if (Object.prototype.hasOwnProperty.call(differences.updated, key)) {
                            updatedValues[key] = v[key];
                          }
                        }

                        const values = {
                          justServicePriceCustomer: updatedValues.valorServico,
                          justServicePriceByPartner: updatedValues.valorServicoParceiro,
                          tollPrice: updatedValues.valorPedagio,
                          accessoryPriceCustomer: updatedValues.acessorios,
                          workingTimePriceCustomer: updatedValues.horaTrabalhada,
                          downtimePriceCustomer: updatedValues.horaParada,
                        };

                        updateSolicitaionValues(solicitation.codigo, values);
                        if (typeof afterSolicitationValues === 'function') {
                          afterSolicitationValues();
                        }
                      })}>
                      Atualizar Valores
                    </button>
                  )}
                </div>
              </div>

              <br />
              <hr />
              {canSeeRealPrice && isDataRealPriceAvailable && (
                <div>
                  <div style={{marginBottom: '10px'}}>
                    <h5
                      style={{
                        textAlign: 'center',
                        border: '1px solid',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Veiculo
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      {this.state.blindado !== null && this.state.blindado !== undefined && (
                        <div>
                          <h5>Blindado </h5>
                          <h5 style={{textAlign: 'center'}}>
                            {this.state.blindado ? 'Sim' : 'Não'}
                          </h5>
                        </div>
                      )}

                      {this.state.capotado !== null && this.state.capotado !== undefined && (
                        <div>
                          <h5>Capotado</h5>
                          <h5 style={{textAlign: 'center'}}>
                            {this.state.capotado ? 'Sim' : 'Não'}
                          </h5>
                        </div>
                      )}

                      {this.state.semRodas !== null && this.state.semRodas !== undefined && (
                        <div>
                          <h5>Sem Rodas</h5>
                          <h5 style={{textAlign: 'center'}}>
                            {this.state.semRodas ? 'Sim' : 'Não'}
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{marginBottom: '10px'}}>
                    <h5
                      style={{
                        textAlign: 'center',
                        border: '1px solid',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Valores reais
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      {this.state.realPrice && (
                        <div>
                          <h5>Valor real corrida</h5>
                          <h5 style={{textAlign: 'center'}}>
                            R${this.state.realPrice.toFixed(2)}
                          </h5>
                        </div>
                      )}
                      {this.state.ComissaoReboqueme && (
                        <div>
                          <h5>Comissao Reboqueme</h5>
                          <h5 style={{textAlign: 'center'}}>
                            {this.state.ComissaoReboqueme}%
                          </h5>
                        </div>
                      )}
                      {this.state.realPricePartner && (
                        <div>
                          <h5>Valor real parceiro</h5>
                          <h5 style={{textAlign: 'center'}}>
                            R${this.state.realPricePartner.toFixed(2)}
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{textAlign: 'center', marginBottom: '20px'}}>
                    <h5
                      style={{
                        border: '1px solid ',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Adicionais
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '50px',
                      }}>
                      {this.state.adicionalNoturno !== null &&
                        this.state.adicionalNoturno !== undefined && (
                          <div>
                            <h5 style={{marginBottom: '15px'}}>Noturno</h5>
                            <h5 style={{textAlign: 'center'}}>
                              R${this.state.adicionalNoturno.toFixed(2)}
                            </h5>
                          </div>
                        )}
                      {this.state.patins !== null && this.state.patins !== undefined && (
                        <div>
                          <h5 style={{marginBottom: '15px'}}>Valor Patins</h5>
                          <h5 style={{textAlign: 'center'}}>
                            {' '}
                            R${this.state.patins.toFixed(2)}
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div style={{ marginBottom: '10px' }}>
                    <h4
                      style={{
                        textAlign: 'center',
                        border: '1px solid',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Valores Saida
                    </h4>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '50px',
                      }}>
                      {this.state.valorSaidaCliente && (
                        <div>
                          <h4>Valor de saida do cliente</h4>
                          <h4 style={{ textAlign: 'center' }}>
                            R${this.state.valorSaidaCliente.toFixed(2)}
                          </h4>
                        </div>
                      )}
                      {this.state.valorSaidaParceiro !== null &&
                        this.state.valorSaidaParceiro !== undefined && (
                          <div>
                            <h4>Valor de saida Parceiro</h4>
                            <h4 style={{ textAlign: 'center' }}>
                              R${this.state.valorSaidaParceiro.toFixed(2)}
                            </h4>
                          </div>
                        )}
                    </div>
                  </div> */}
                  {/*  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h3
                      style={{
                        border: '1px solid ',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Tempo de espera
                    </h3>
                    {this.state.tempoEspera && (
                      <div>
                        <h2 style={{ textAlign: 'center', marginTop: '20px' }}>
                          {this.state.tempoEspera} Min
                        </h2>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3
                      style={{
                        textAlign: 'center',
                        border: '1px solid',
                        padding: '5px',
                        color: '#fff',
                        backgroundColor: '#5c40ff',
                        borderRadius: '5px',
                      }}>
                      Quilometragem excedente
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '50px',
                      }}>
                      {this.state.kmExcedenteParceiro !== null &&
                        this.state.kmExcedenteParceiro !== undefined && (
                          <div>
                            <h3>Km do parceiro</h3>
                            <h3 style={{ textAlign: 'center' }}>
                              {this.state.kmExcedenteParceiro.toFixed(0)}
                            </h3>
                          </div>
                        )}
                      {this.state.kmExcedenteCliente !== null &&
                        this.state.kmExcedenteCliente !== undefined && (
                          <div>
                            <h3>Km do cliente</h3>
                            <h3 style={{ textAlign: 'center' }}>
                              {this.state.kmExcedenteCliente.toFixed(0)}
                            </h3>
                          </div>
                        )}
                    </div>
                  </div>*/}
                </div>
              )}
              {/* <button className="btn btn-primary btn-edit-info">
                Visualizar valor real
              </button> */}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownprops) => {
  if (ownprops.solicitation)
    return {
      valuesUpdated: state.relatorio.valuesUpdated,
      initialValues: {
        valorServico: ownprops.solicitation.valorServico,
        valorServicoParceiro: ownprops.solicitation.valorServicoParceiro,
        valorParceiro: ownprops.solicitation.valorParceiro,
        acessorios: ownprops.solicitation.valorAcessorios,
        valorPedagio: ownprops.solicitation.valorPedagio,
        horaParada: ownprops.solicitation.valorHoraParada,
        horaTrabalhada: ownprops.solicitation.valorHoraTrabalhada,
        valorCorrida: ownprops.solicitation.valorCorrida,
        comissaoReboqueme: ownprops.solicitation.comissaoReboqueme,
      },
      login: state.login,
    };
  else
    return {
      valuesUpdated: state.relatorio.valuesUpdated,
      initialValues: {
        valorServico: 0,
        valorServicoParceiro: 0,
        valorParceiro: 0,
        acessorios: 0,
        valorPedagio: 0,
        horaParada: 0,
        horaTrabalhada: 0,
        valorCorrida: 0,
        comissaoReboqueme: 0,
      },
      login: state.login,
    };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSolicitaionValues,
      updateSolicitationPDF,
    },
    dispatch,
  );

EditInfo = reduxForm({
  enableReinitialize: true,
  form: 'editInfo',
})(EditInfo);

EditInfo.propTypes = {
  afterSolicitationValues: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInfo);
