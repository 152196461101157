import React, {Component} from 'react';

import toastr from 'toastr';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconBadge from './IconBadge';
import FastButton from './fastButton';
import {CustomModalWithoutTabIndex, doCloseModal, doOpenModal} from './CustomModal';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';
import {
  hasBaseProfile,
  hasCompanyProfile,
  isFromCompany,
  hasAccessToPublicHistoryPost,
} from '../../utils/check_permission';
import {getEstagios} from '../../utils/attendance_helper';
import {
  CODIGO_DAF,
  CODIGO_KOVI,
  HISTORICO_MODO_PRIVADO,
  HISTORICO_MODO_PUBLICO,
} from '../../reducers/constants/index';
import {BASE_URL_V2} from '../../config';
import userIcon from '../../assets/img/user-icon.png';
import {typeFailureDafHelper} from '../../api/type_helper';

const MODAL_NAME = 'modal-new-historic';
const MODAL_NAME_IFRAME = 'modal-new-iframe';

const tipoMotivoDAFOptions = [
  {
    value: 'DEFEITO_MOTOR',
    label: 'Motor',
  },
  {
    value: 'DEFEITO_POS-TRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'DEFEITO_TRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'DEFEITO_SISTEMA-ELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'DEFEITO_CABINE',
    label: 'Cabine',
  },
  {
    value: 'DEFEITO_AR',
    label: 'Sistema de ar',
  },
  {
    value: 'DEFEITO_EIXO-TRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'DEFEITO_EIXO-DIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'DEFEITO_SISTEMA-DE-FREIO',
    label: 'Sistema de freio',
  },
];

const tipoMotivoOptions = [
  {
    value: 'TIPOASSISTENCIAEIXOTRACAO',
    label: 'Eixo Tração',
  },
  {
    value: 'DEFEITO_MOTOR',
    label: 'Motor',
  },
  {
    value: 'DEFEITO_POS-TRATAMENTO',
    label: 'Sistema de pós tratamento',
  },
  {
    value: 'DEFEITO_TRANSMISSAO',
    label: 'Transmissão',
  },
  {
    value: 'DEFEITO_SISTEMA-ELETRICO',
    label: 'Sistema elétrico',
  },
  {
    value: 'DEFEITO_CABINE',
    label: 'Cabine',
  },
  {
    value: 'TIPOASSISTENCIASISTEMADEESCAPE',
    label: 'Sistema de escape',
  },
  {
    value: 'DEFEITO_AR',
    label: 'Sistema de ar',
  },
  {
    value: 'DEFEITO_EIXO-TRASEIRO',
    label: 'Eixo Traseiro',
  },
  {
    value: 'DEFEITO_EIXO-DIANTEIRO',
    label: 'Eixo Dianteiro',
  },
  {
    value: 'DEFEITO_SISTEMA-DE-FREIO',
    label: 'Sistema de freio',
  },
  {
    value: 'TIPOASSISTENCIAOUTROS',
    label: 'Outros',
  },
];

class NewStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conclusionTab: {
        tipoMotivo: undefined,
        tipoDefeito: undefined,
        defeito: undefined,
      },
      failureDafTypes: [],
      failureDafTypes: [],
    };
    this.getEmails = this.getEmails.bind(this);
  }

  getEmails() {
    if (
      this.props.attendanceTab.enderecoFavorito &&
      this.props.attendanceTab.enderecoFavorito.contatosEnderecoFavorito
    ) {
      return this.props.attendanceTab.enderecoFavorito.contatosEnderecoFavorito.map(
        (contato) => ({
          nivelPrioridade: contato.nivelPrioridade,
          value: contato.email,
          jobTitle: contato.cargo,
        }),
      );
    }
    return [];
  }

  addEmail() {
    email;
  }
  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  handleEmail(emailList) {
    if (emailList.charAt(emailList.length - 1) === ';') {
      emailList = emailList.substring(0, emailList.length - 1);
    }
    let emails = emailList.replace(',', ';').split(';');
    const allValid = emails.every(this.validateEmail);
    return allValid;
    // return this.props.handleAtendimento({email:emailList})
  }

  async componentDidMount() {
    const defeitosAPI = await typeFailureDafHelper();
    const defeitos = {};
    if (defeitosAPI) {
      const data = defeitosAPI.data;

      tipoMotivoDAFOptions.forEach((tipo) => {
        defeitos[tipo.value] = data
          .filter((defeito) => defeito.code.includes(tipo.value))
          .map(({description, code}) => ({value: code, label: description}));
      });

      this.setState({
        ...this.state,
        failureDafTypes: defeitos,
      });
    }
  }

  isDAFUser() {
    const {login} = this.props;

    const dafId = '2322661870558778503';
    return login.user_info.empresa.codigo === dafId;
  }

  isDAFAttendence() {
    const {attendanceTab} = this.props;

    if (attendanceTab && attendanceTab.empresa && attendanceTab.empresa.codigo) {
      const dafId = '2322661870558778503';
      return attendanceTab.empresa.codigo === dafId;
    }

    return false;
  }

  render() {
    const {
      atendimento = {},
      handleAtendimento,
      putLog,
      attendanceTab,
      assistanceCode,
      finishAttendance,
      log,
    } = this.props;

    const isAssistencia =
      attendanceTab &&
      attendanceTab.tipoRequisicao &&
      attendanceTab.tipoRequisicao.codigo === 'REQUISICAOTIPOASSISTENCIA';

    let tipoMotivoReal = this.state.conclusionTab.tipoMotivo;
    if (!tipoMotivoReal && this.props.attendanceTab && this.props.attendanceTab.tipoMotivo) {
      tipoMotivoReal = tipoMotivoOptions.find(
        ({value}) => value === this.props.attendanceTab.tipoMotivo.codigo,
      );
    }

    let defeitoOptions = [];
    if (this.state.conclusionTab.tipoMotivo) {
      defeitoOptions = this.state.failureDafTypes[this.state.conclusionTab.tipoMotivo.value];
    } else {
      defeitoOptions = this.state.failureDafTypes['DEFEITO_MOTOR'];
    }

    const {
      willSleep,
      mecanicoChegou,
      estagio,
      sendMail,
      email,
      adormecerAte,
      dataAgendado,
      kmTotalRodadoMecanico,
      kmAtualValidado,
    } = atendimento;

    const tipoRequisicao =
      attendanceTab && attendanceTab.tipoRequisicao && attendanceTab.tipoRequisicao.codigo;
    const estagios = getEstagios();

    let estagiosFormatted = [];

    if (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO') {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: '1. Agendando', value: 'AGENDANDO'},
        {label: '2. Agendado', value: 'ESTAGIOOPERADOR'},
        {label: '3. Concluído', value: 'CONCLUIDO'},
      ];
    } else if (
      tipoRequisicao === 'REQUISICAOTIPOINFORMACAO' ||
      tipoRequisicao === 'REQUISICAOTIPOSUPORTE'
    ) {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: 'Operador', value: 'ESTAGIOOPERADOR'},
        {label: 'Concluído', value: 'CONCLUIDO'},
      ];
    } else if (tipoRequisicao === 'REQUISICAOTIPORECUSA') {
      estagiosFormatted = [
        {label: 'Validado', value: 'ESTAGIOVALIDADO', isDisabled: true},
        {label: 'Operador', value: 'ESTAGIOOPERADOR'},
        {label: 'Concluído', value: 'CONCLUIDO'},
      ];
    } else {
      estagiosFormatted = estagios.map((estagio) => {
        if (estagio.value === 'ESTAGIOVALIDADO') {
          if (!estagio.isDisabled) {
            estagio.label = `${estagio.label} (Status adicionado ao abrir o atendimento)`;
          }
          estagio.isDisabled = true;
        }

        return estagio;
      });
    }
    const options = estagiosFormatted;
    const estagioSelected = options.filter((option) => option.value === estagio);
    const estagioSelectedValue =
      Array.isArray(estagioSelected) && estagioSelected.length && estagioSelected[0].value;

    const fieldEmail = {
      title: 'Enviar e-mail para',
      placeholder: 'example@gosoon.co',
    };
    if (
      (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
        estagioSelectedValue === 'ESTAGIOOPERADOR') ||
      estagioSelectedValue === 'ESTAGIOMECANICO'
    ) {
      fieldEmail.title = 'Enviar e-mail para concessionária';
      fieldEmail.placeholder = 'Adicionar e-mail em cópia (cc)';
    }

    Array.isArray(estagioSelected) && estagioSelected.length && estagioSelected[0].value;
    const isShowForm =
      tipoRequisicao === 'REQUISICAOTIPOASSISTENCIA' &&
      estagioSelectedValue === 'CONCLUIDO' &&
      (this.props.currentEstagio.codigo === 'ESTAGIOOPERADOR' ||
        this.props.currentEstagio.codigo === 'ESTAGIOMECANICO' ||
        this.props.currentEstagio.codigo === 'ESTAGIOTELECONFERENCIA');

    let showFieldEmail =
      (estagioSelectedValue === 'CONCLUIDO' &&
        tipoRequisicao === 'REQUISICAOTIPOINFORMACAO') ||
      (estagioSelectedValue !== 'AGENDANDO' && estagioSelectedValue !== 'CONCLUIDO');

    if (
      tipoRequisicao === 'REQUISICAOTIPOASSISTENCIA' &&
      estagioSelectedValue !== 'ESTAGIOMECANICO' &&
      estagioSelectedValue !== 'ESTAGIOGUINCHO'
    ) {
      showFieldEmail = false;
    }
    if (tipoRequisicao === 'REQUISICAOTIPORECUSA' && estagioSelectedValue === 'CONCLUIDO') {
      showFieldEmail = false;
    }
    return (
      <div>
        <CustomModalWithoutTabIndex
          title={'Novo Histórico de Atendimento'}
          customClass={isShowForm ? 'modal-test' : ''}
          context={MODAL_NAME}>
          <div style={{display: 'flex', gap: '24px'}}>
            <div
              style={{
                width: isShowForm ? '50%' : '100%',
              }}>
              <div className="row">
                <div className="col-sm-12 margin-bottom-15">
                  <label>Estágio do Atendimento</label>
                  <Select
                    options={options}
                    placeholder="Selecione um estágio"
                    onChange={(e) => handleAtendimento({estagio: e.value})}
                    value={estagioSelected}
                    styles={selectCustomStyle}
                  />
                </div>
              </div>
              {tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                estagioSelectedValue === 'ESTAGIOOPERADOR' && (
                  <div className="row">
                    <div className="col-sm-12 margin-bottom-15">
                      <label>Data e horário do agendamento</label>
                      <input
                        type="datetime-local"
                        tabIndex="3"
                        placeholder=""
                        value={dataAgendado}
                        className="form-control"
                        onChange={(e) =>
                          handleAtendimento({
                            dataAgendado: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

              {!(
                (tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                  estagioSelectedValue === 'ESTAGIOOPERADOR') ||
                estagioSelectedValue === 'CONCLUIDO'
              ) && (
                <div className="row">
                  <div className="col-sm-12 margin-bottom-15">
                    <input
                      type="checkbox"
                      checked={willSleep}
                      onChange={(e) => handleAtendimento({willSleep: !willSleep})}
                      value={willSleep}
                    />
                    <label>Adormecer até</label>
                    <input
                      type="datetime-local"
                      tabIndex="3"
                      placeholder=""
                      className="form-control"
                      disabled={!willSleep}
                      value={adormecerAte}
                      onChange={(e) => handleAtendimento({adormecerAte: e.target.value})}
                      format={moment().local().format('YYYY-MM-DD HH:mm')}
                    />
                  </div>
                </div>
              )}

              {showFieldEmail && (
                <div className="row">
                  <div className="col-sm-12 margin-bottom-15">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleAtendimento({
                          sendMail: !sendMail,
                        })
                      }
                      value={sendMail}
                      checked={sendMail}
                    />
                    <label>{fieldEmail.title}</label>
                    <input
                      type="email"
                      placeholder={fieldEmail.placeholder}
                      className="form-control"
                      disabled={!sendMail}
                      onChange={(e) => handleAtendimento({email: e.target.value})}
                      value={email}
                    />
                    <FastButton
                      labels={this.getEmails()}
                      btnClick={(value) => {
                        sendMail ? handleAtendimento({email: `${email} ${value};`}) : null;
                      }}
                    />
                  </div>
                </div>
              )}

              {estagioSelectedValue === 'ESTAGIOMECANICO' && (
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleAtendimento({
                            mecanicoChegou: !mecanicoChegou,
                          })
                        }
                        value={mecanicoChegou}
                        checked={mecanicoChegou}
                      />
                      <label>
                        Técnico chegou?{' '}
                        {attendanceTab.kmAtualValidado || attendanceTab.kmTotalRodadoMecanico
                          ? 'SIM'
                          : 'NÃO'}
                      </label>
                    </div>
                  </div>

                  <div className="row margin-bottom-15">
                    <div className="col-sm-6">
                      <input
                        type="number"
                        placeholder="Confirmação km cliente"
                        className="form-control"
                        disabled={!mecanicoChegou}
                        onChange={(e) => handleAtendimento({kmAtualValidado: e.target.value})}
                        value={kmAtualValidado || attendanceTab.kmAtualValidado}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        placeholder="Km que técnico percorreu"
                        className="form-control col-md-6"
                        disabled={!mecanicoChegou}
                        onChange={(e) =>
                          handleAtendimento({kmTotalRodadoMecanico: e.target.value})
                        }
                        value={kmTotalRodadoMecanico || attendanceTab.kmTotalRodadoMecanico}
                      />
                    </div>
                  </div>
                </div>
              )}

              {estagioSelectedValue === 'CONCLUIDO' &&
                !isFromCompany(CODIGO_KOVI) &&
                isAssistencia && (
                  <div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Motivo do atendimento</label>
                        <Select
                          options={
                            this.isDAFUser() || this.isDAFAttendence()
                              ? tipoMotivoDAFOptions
                              : tipoMotivoOptions
                          }
                          placeholder="Selecione o tipo de assistência"
                          styles={{margin: '5px 0', ...selectCustomStyle}}
                          onChange={(tipoMotivo) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                tipoMotivo: tipoMotivo,
                                tipoDefeito: null,
                              },
                            });
                          }}
                          value={tipoMotivoReal}
                        />
                      </div>
                    </div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Tipo do defeito</label>

                        <Select
                          options={defeitoOptions}
                          placeholder="Selecione o tipo do defeito"
                          onChange={(tipoDefeito) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                tipoDefeito,
                              },
                            });
                          }}
                          styles={{margin: '5px 0', ...selectCustomStyle}}
                          value={this.state.conclusionTab.tipoDefeito}
                        />
                      </div>
                    </div>
                    <div className="row margin-bottom-15">
                      <div className="col-sm-12">
                        <label>Solução do atendimento com o código das peças</label>

                        <textarea
                          rows="5"
                          cols="50"
                          style={{width: '100%', fontSize: '15px'}}
                          onChange={(e) => {
                            this.setState({
                              conclusionTab: {
                                ...this.state.conclusionTab,
                                defeito: e.target.value,
                              },
                            });
                          }}
                          value={this.state.conclusionTab.defeito}
                        />
                      </div>
                    </div>
                  </div>
                )}

              <div className="pull-right margin-bottom-15">
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    if (sendMail) {
                      const isValid = this.handleEmail(email);
                      if (!isValid) {
                        toastr.error('Um ou mais emails não são válidos', 'Erro de validação');
                        return;
                      }
                    }
                    // return;
                    if (estagioSelectedValue === 'CONCLUIDO') {
                      if (
                        tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                        !attendanceTab.dataAgendado
                      ) {
                        const canGenerate = await Swal.fire({
                          title:
                            'Tem certeza que deseja concluir o atendimento sem informar a data do agendamento?',
                          showCancelButton: true,
                          icon: 'warning',
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Concluir',
                        })
                          .then((value) => value.dismiss !== 'cancel')
                          .catch((error) => console.log(error));

                        if (!canGenerate) {
                          return;
                        }
                      }
                      let rest;
                      try {
                        rest = {
                          tipoMotivo: tipoMotivoReal.value,
                          tipoDefeito: this.state.conclusionTab.tipoDefeito.value,
                          partNumber: this.state.conclusionTab.defeito,
                        };
                      } catch (error) {}

                      finishAttendance(assistanceCode, log, email, _, rest)
                        .then((res) => {
                          if (res.sucesso) {
                            doCloseModal(MODAL_NAME);
                            doCloseModal(MODAL_NAME_IFRAME);
                            browserHistory.push(`/assistance`);
                          }
                        })
                        .catch((error) => console.log(error));
                    } else {
                      if (
                        tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
                        estagioSelectedValue === 'ESTAGIOOPERADOR' &&
                        !dataAgendado
                      ) {
                        toastr.error(
                          'É necessário informar a data do agendamento',
                          'Erro de validação',
                        );
                        return;
                      }

                      await putLog().then((res) => {
                        if ((res && res.sucesso) || !res) {
                          doCloseModal(MODAL_NAME);
                          doCloseModal(MODAL_NAME_IFRAME);
                        }
                      });
                    }
                  }}>
                  {estagioSelectedValue === 'CONCLUIDO' ? 'Concluir' : 'Salvar'}
                </button>
              </div>
            </div>
            {isShowForm && (
              <div>
                <iframe
                  src="https://forms.office.com/Pages/ResponsePage.aspx?id=hc2i_s659EeeJN6QNpvDbfNIp0sSIwZMnLJ1xO7pTghUN0NBSzhCNVlQVVc2VjhXV0Y0MVZUM1pVOSQlQCN0PWcu"
                  height="680px"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  align="left"
                  width="680px"
                  className="modal-iframe">
                  carregando...
                </iframe>
              </div>
            )}
          </div>
        </CustomModalWithoutTabIndex>
      </div>
    );
  }
}

const mountLogList = (logList, isScreenAtendimento) =>
  logList
    .sort(
      (a, b) =>
        moment(b.dataCadastro, 'DD/MM/YYYY HH:mm:ss') -
        moment(a.dataCadastro, 'DD/MM/YYYY HH:mm:ss'),
    )
    .map((log, i) => {
      const direction = log.usuario.login === getUserInfo().usuario.login ? 'right' : 'left';

      return (
        <div className="chat-element" key={'log' + i}>
          <div
            style={{
              display: 'flex',
              flexDirection: direction === 'right' ? 'row-reverse' : 'row',
            }}>
            <div
              className="image-cntainer"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <a href="#" className={`float-${direction}`}>
                <img
                  alt="image"
                  className="rounded-circle"
                  src={log.usuario.linkFotoPerfil ? log.usuario.linkFotoPerfil : userIcon}
                />
              </a>
              {isScreenAtendimento && (
                <IconBadge
                  customType={{
                    color: 'success',
                    icon: '',
                    description: log.estagio,
                  }}
                />
              )}
            </div>

            <div className={`media-body text-${direction}`}>
              <strong>{log.usuario.nome}</strong>
              <p className="m-b-xs">{log.descricao}</p>
              {log.visibilidade && log.visibilidade.codigo === HISTORICO_MODO_PRIVADO && (
                <small className="text-warning" style={{marginRight: '4px'}}>
                  Privada
                </small>
              )}
              <small className="text-muted">
                {moment(log.dataCadastro, 'DD/MM/YYYY HH:mm').format('LLLL')}
              </small>
            </div>
          </div>
          <small className={`text-${direction} pull-left`}>
            {log.email && (
              <div>
                <small className="text-muted" style={{marginLeft: 5, marginTop: 7}}>
                  * E-mail enviado para: {log.email}
                </small>
              </div>
            )}
            {log.adormecerAte && (
              <div>
                <small className="text-muted" style={{marginLeft: 5, marginTop: 7}}>
                  * Adormecido até{' '}
                  {moment(log.adormecerAte, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}
                </small>
              </div>
            )}
          </small>
        </div>
      );
    });

const getUserInfo = () => JSON.parse(localStorage.getItem('user_info'));

const confirmPublicLog = (callback, log) => {
  Swal.fire({
    title: 'Tem certeza que deseja enviar um histórico público?',
    text:
      'O histórico público ficará disponível para todos os usuários da plataforma, inclusive a empresa.',
    showCancelButton: true,
    icon: 'warning',
    reverseButtons: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Enviar',
    input: 'checkbox',
    inputValue: 0,
    inputPlaceholder: 'Estou ciente que o histórico será público',
    inputValidator: (result) => {
      if (!result) {
        return 'Você precisa confirmar que está ciente que o histórico será público';
      } else {
        callback(log);
      }
    },
  });
};

class RelatoriosLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  onChangeModal(modal) {
    this.setState({showModal: modal});
  }

  render() {
    const {
      putLog,
      logList,
      handleLog,
      log,
      onKeyUp,
      visibility,
      onChangeVisibility,
      atendimento,
      handleAtendimento,
      isScreenAtendimento,
      currentEstagio = {descricao: ''},
      attendanceTab,
      assistanceCode,
      finishAttendance,
      login,
      disableButton = false,
    } = this.props;

    const tipoRequisicao =
      attendanceTab && attendanceTab.tipoRequisicao && attendanceTab.tipoRequisicao.codigo;
    tipoRequisicao;

    const estagioFormatted =
      tipoRequisicao === 'REQUISICAOTIPOAGENDAMENTO' &&
      attendanceTab &&
      attendanceTab.dataAgendado
        ? `Agendado para ${attendanceTab.dataAgendado}`
        : currentEstagio.descricao;

    return (
      <div className="col-md-12" style={{marginBottom: '10px'}}>
        {isScreenAtendimento && (
          <div className="pull-right margin-bottom-15">
            <h4>
              Estágio atual: &nbsp;
              <IconBadge
                customType={{
                  color: 'primary',
                  icon: '',
                  description: estagioFormatted,
                }}
              />
            </h4>
          </div>
        )}
        <NewStatusModal
          atendimento={atendimento}
          handleAtendimento={handleAtendimento}
          putLog={putLog}
          attendanceTab={attendanceTab}
          assistanceCode={assistanceCode}
          finishAttendance={finishAttendance}
          currentEstagio={currentEstagio}
          log={log}
          login={login}
        />
        <div className="ibox logChat">
          <div className="ibox-content">
            <div>
              <div className="chat-activity-list">
                {mountLogList(logList ? logList : [], isScreenAtendimento)}
              </div>
            </div>
          </div>
          {!hasBaseProfile() && (
            <div className="chat-form logChat-input">
              <div className="form-group">
                <div className="col-md-11">
                  <textarea
                    onKeyUp={log.length <= 4000 ? onKeyUp : null}
                    className="form-control"
                    placeholder="Ctrl + enter para enviar mensagem"
                    value={log}
                    onChange={handleLog}
                  />
                </div>
                <div className="col-md-1" style={{display: 'flex', gap: '8px'}}>
                  <button
                    disabled={disableButton ? true : log.length > 4000}
                    className="btn btn-sm btn-primary m-t-n-xs btn-chat"
                    id="focusButton"
                    onClick={() => {
                      if (log) {
                        if (isScreenAtendimento) {
                          doOpenModal(MODAL_NAME);
                          doOpenModal(MODAL_NAME_IFRAME);
                        } else if (
                          visibility === HISTORICO_MODO_PUBLICO &&
                          !hasCompanyProfile()
                        ) {
                          confirmPublicLog(() => putLog(), log);
                        } else {
                          putLog();
                        }
                      } else {
                        toastr.error(
                          'É necessário escrever alguma mensagem para criar um histórico',
                          'Erro de validação',
                        );
                      }
                    }}>
                    Enviar
                  </button>
                </div>

                {visibility &&
                  !hasCompanyProfile() &&
                  hasAccessToPublicHistoryPost() &&
                  !hasBaseProfile() && (
                    <div style={{marginTop: '5px'}}>
                      <label
                        className="form-check-label"
                        style={{marginLeft: '15px', marginTop: '5px'}}>
                        <input
                          type="radio"
                          className="form-check-input"
                          name="visibility"
                          value={HISTORICO_MODO_PRIVADO}
                          onChange={onChangeVisibility}
                          checked={visibility === HISTORICO_MODO_PRIVADO}
                        />
                        <span>Privada</span>
                      </label>
                      <label
                        className="form-check-label"
                        style={{marginLeft: '15px', marginTop: '5px'}}>
                        <input
                          type="radio"
                          name="visibility"
                          className="form-check-input"
                          value={HISTORICO_MODO_PUBLICO}
                          onChange={onChangeVisibility}
                          checked={visibility === HISTORICO_MODO_PUBLICO}
                        />
                        <span>Pública</span>
                      </label>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attendanceTab: state.attendance.attendanceTab,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAttendance: (code, stats, doAfterSucess) => {
      dispatch(finishAttendance(code, stats, doAfterSucess));
    },
  };
};

RelatoriosLog.propTypes = {
  visibility: PropTypes.string,
  log: PropTypes.string.isRequired,
  handleLog: PropTypes.func,
  putLog: PropTypes.func.isRequired,
  currentEstagio: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosLog);
