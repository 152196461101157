import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {BootstrapTable} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {TableHeaderColumn} from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {createFranchiseEditor} from './FranchiseEditor';
import {createCancelAttendenceSolicitation} from './CancelAttendenceSolicitation';
import {statusCircle} from '../../../utils/status_helper';
import {cancelAttendenceSolicitation} from '../../../api/solicitation_helper';
import {CompaniesContracts} from '../../../actions/relatorio_actions';

class AssistanceSolicitationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  nameFormat(cell, row) {
    return (
      <div>
        {row.cliente.nome} - {row.cliente.telefoneCelular}
      </div>
    );
  }

  servicoFormat(cell, row) {
    return <div title={row.tipoServico.descricao}>{row.tipoServico.descricao}</div>;
  }

  franchiseFormat(cell, row) {
    return row.franquia ? (
      <div title="Sim" style={{cursor: 'pointer'}}>
        Sim <i className="fas fa-edit fa-lg"></i>
      </div>
    ) : (
      <div title="Não" style={{cursor: 'pointer'}}>
        Não <i className="fas fa-edit fa-lg"></i>
      </div>
    );
  }

  valorFormat(cell, row) {
    return <div title={`R$ ${row.valorCorrida}`}>{`R$ ${row.valorCorrida}`}</div>;
  }

  attendanceFormat(cell, row) {
    if (!row.user) {
      return <div></div>;
    }
    const nameInfo = row.user.nome;
    const department = row.user.departamento ? row.user.departamento.descricao : null;

    const name = `${nameInfo} ${department ? `- ${department}` : ''}`;

    return <div title={name}>{name}</div>;
  }

  enderecoFormat(cell, row) {
    return (
      <div title={row.enderecoOrigem ? row.enderecoOrigem.logradouro : null}>
        {row.enderecoOrigem ? row.enderecoOrigem.logradouro : '-'}
      </div>
    );
  }

  cancelFormat(cell, row) {
    return <button className="btn btn-danger">Cancelar</button>;
  }

  setTableLoader() {
    if (this.props.solicitationFetching === false) {
      return `Nenhum registro encontrado`;
    } else {
      return (
        <div className="spiner-example">
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1" />
            <div className="sk-double-bounce2" />
          </div>
        </div>
      );
    }
  }

  onSearchChange(input) {
    this.setState({
      ...this.state,
      filter: input,
    });
  }

  clearSearch() {
    this.setState({
      ...this.state,
      filter: '',
    });
  }

  render() {
    const {showInfo, solicitations, attendance} = this.props;
    const contractType = attendance.empresa.tipoContrato.codigo;

    const options = {
      noDataText: this.setTableLoader(), //'Nenhum registro encontrado.'
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
      ],
      sizePerPage: 100, // which size per page you want to locate as default
    };

    return (
      <div
        className={classnames({
          hide: !showInfo,
        })}
        style={{marginTop: '35px'}}>
        <BootstrapTable
          data={solicitations}
          onPageChange
          multiColumnSearch
          options={options}
          pagination
          striped
          cellEdit={{mode: 'click'}}
          hover
          bordered={false}>
          <TableHeaderColumn
            dataField={'status'}
            dataFormat={statusCircle}
            width="80"
            editable={false}
          />
          <TableHeaderColumn isKey dataField="dataCadastro" width="140" editable={false}>
            Data
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.attendanceFormat} width="200" editable={false}>
            Usuário
          </TableHeaderColumn>
          <TableHeaderColumn dataField="codigo" width="160" editable={false}>
            Solicitação
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.valorFormat}
            dataAlign="center"
            width="120"
            editable={false}>
            Valor
          </TableHeaderColumn>
          {contractType === CompaniesContracts.FREQUENCIA && (
            <TableHeaderColumn
              dataFormat={this.franchiseFormat}
              dataAlign="center"
              width="120"
              dataField="franquia"
              customEditor={{getElement: createFranchiseEditor}}>
              Franquia
            </TableHeaderColumn>
          )}
          <TableHeaderColumn dataFormat={this.servicoFormat} width="120" editable={false}>
            Serviço
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.enderecoFormat} width="500" editable={false}>
            Endereço Origem
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.cancelFormat}
            dataAlign="center"
            width="160"
            dataField="cancelar"
            customEditor={{getElement: createCancelAttendenceSolicitation}}>
            Cancelamento
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

AssistanceSolicitationTable.propTypes = {
  showInfo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  solicitationFetching: state.attendance.hasSolicitationFetching,
  solicitations: state.attendance.solicitation,
  attendance: state.attendance.attendanceTab,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssistanceSolicitationTable);
