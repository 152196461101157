import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateTime from "react-datetime";
import { finishAttendance } from "../../../actions/attendence_actions";
import moment from "moment";
import { browserHistory } from "react-router";
import swal from "sweetalert";

class ConclusionTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualKm: this.props.actualKm,
      totalKm: this.props.totalKm,
      dataAgendada: this.props.dataAgendada || null,
    };

    this.handleConclusion = this.handleConclusion.bind(this);
    this.doAfterCloseAttendance = this.doAfterCloseAttendance.bind(this);
    this.prepareCloseAttendance = this.prepareCloseAttendance.bind(this);
    this.fetchFinishAttendance = this.fetchFinishAttendance.bind(this);
  }

  handleConclusion() {
    const {
      attendanceCode,
      attendanceTab: {
        tipoRequisicao: { codigo = null },
      },
    } = this.props;

    let dataAgendado = null;

    if (codigo === "REQUISICAOTIPOAGENDAMENTO" && this.state.dataAgendada) {
      dataAgendado = moment(
        this.state.dataAgendada,
        "DD/MM/YYYY HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm:ss");
    }

    this.prepareCloseAttendance(codigo, attendanceCode, dataAgendado);
  }

  prepareCloseAttendance(tipoRequisicao, attendanceCode, dataAgendado) {
    if (tipoRequisicao === "REQUISICAOTIPOAGENDAMENTO") {

      swal({
        title: "Informe o email",
        type: "input",
        inputType: "email",
        inputValue: "",
        showCancelButton: true,
      }, (email) => {
        if (email !== false) {
          this.fetchFinishAttendance(attendanceCode, dataAgendado, email);
        }
      });

      return;
    }

    this.fetchFinishAttendance(attendanceCode, dataAgendado);
  }

  fetchFinishAttendance(attendanceCode, dataAgendado, email=null) {
    const {closeAttendance } = this.props;
    closeAttendance(
      attendanceCode,
      {
        kmAtualValidado: this.state.actualKm,
        kmTotalRodadoMecanico: this.state.totalKm,
        dataAgendado,
        email,
      },
      this.doAfterCloseAttendance
    );
  }

  doAfterCloseAttendance(resp) {
    browserHistory.push(`/assistance`);
  }

  render() {
    const tipoRequisicao =
      this.props.attendanceTab &&
      this.props.attendanceTab.tipoRequisicao &&
      this.props.attendanceTab.tipoRequisicao.codigo;

    const estagio =
      this.props.attendanceTab &&
      this.props.attendanceTab.estagio &&
      this.props.attendanceTab.estagio.codigo;

    return (
      <div
        className="ibox-content"
        style={{
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <section style={{ flex: 1 }}>
          <div className="row">
            <div className="col-sm-4">
              <label className="font-normal p-w-xs">
                Km cliente confirmado
              </label>
              <div style={{ marginBottom: "20px" }} className="input-group">
                <span className="input-group-addon">
                  <i className="fas fa-truck" />
                </span>
                <div className="input-group-append">
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.actualKm}
                    onChange={(e) =>
                      this.setState({ ...this.state, actualKm: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            {tipoRequisicao === "REQUISICAOTIPOAGENDAMENTO" && (
              <div className="col-sm-4">
                <label className="font-normal p-w-xs">Data Agendada</label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="far fa-id-card" />
                  </span>
                  <DateTime
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    onChange={(dataAgendada) =>
                      this.setState({ ...this.state, dataAgendada })
                    }
                    value={this.state.dataAgendada}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-4">
              <label className="font-normal p-w-xs">Km total técnico</label>
              <div style={{ marginBottom: "20px" }} className="input-group">
                <span className="input-group-addon">
                  <i className="fas fa-car-side" />
                </span>
                <div className="input-group-append">
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.totalKm}
                    onChange={(e) =>
                      this.setState({ ...this.state, totalKm: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="row"
        >
          {estagio === "ESTAGIOCONFIRMADO" && (
            <div className="col-md-2">
              <button
                onClick={this.handleConclusion}
                className="btn btn-block btn-primary"
              >
                Concluir
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attendanceTab: state.attendance.attendanceTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAttendance: (code, stats, doAfterSucess) => {
      dispatch(finishAttendance(code, stats, doAfterSucess));
    },
  };
};

ConclusionTab.propTypes = {
  actualkm: PropTypes.string,
  totalKm: PropTypes.string,
  attendanceCode: PropTypes.string.isRequired,
  dataAgendada: PropTypes.string,
};

ConclusionTab.defaultProps = {
  actualKm: "",
  totalKm: "",
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConclusionTab);
