export const BASE_URL = process.env.REACT_APP_API_BASE_URL; // prod
export const BASE_URL_V2 = process.env.REACT_APP_API_BASE_URL_V2; // prod
export const V2_APP_URL = process.env.REACT_APP_V2_URL; // prod

export const HOST_SOLICITATION_SERVICE = 'https://services.soon.com.vc'; // prod

export const MAPS_KEY = process.env.REACT_APP_MAPS_KEY; // chaves oficiais

export const ServiceTypes = {
  REBOQUE: {
    icon: 'fas fa-truck',
    description: 'Reboque',
    color: 'success',
  },
  TROCAPNEU: {
    icon: 'far fa-circle',
    description: 'Troca Pneu',
    color: 'success',
  },
  PANESECA: {
    icon: 'fas fa-flask',
    description: 'Pane Seca',
    color: 'success',
  },
  MECANICO: {
    icon: 'fas fa-wrench',
    description: 'Técnico',
    color: 'success',
  },
  BATERIA: {
    icon: 'fas fa-battery-quarter',
    description: 'Bateria',
    color: 'success',
  },
  CHAVEIRO: {
    icon: 'fas fa-key',
    description: 'Chaveiro',
    color: 'success',
  },
  SUBSOLO: {
    icon: 'fas fa-truck',
    description: 'Guincho Subsolo',
    color: 'success',
  },
  ANJODAGUARDA: {
    icon: 'fas fa-user-shield',
    description: 'Anjo da Guarda',
    color: 'success',
  },
  ELETRICAHOME: {
    icon: 'fa fa-bolt',
    description: 'Elétrica Residencial',
    color: 'success',
  },
  HIDRAULICAHOME: {
    icon: 'fa fa-wrench',
    description: 'Hidráulica Residencial',
    color: 'success',
  },
  CHAVEIROHOME: {
    icon: 'fa fa-key',
    description: 'Chaveiro Residencial',
    color: 'success',
  },
  TAXI: {
    icon: 'fa fa-taxi',
    description: 'Transporte de Passageiro',
    color: 'success',
  },
  VIDROSAUTO: {
    icon: 'fas fa-car-side',
    description: 'Vidraceiro Automotivo',
    color: 'success',
  },
  VIDROSHOME: {
    icon: 'fa fa-window-maximize',
    description: 'Vidraceiro Residencial',
    color: 'success',
  },
  CARRORESERVA: {
    icon: 'fa fa-shuttle-van',
    description: 'Carro Reserva',
    color: 'success',
  },
};

export const StatusTypes = {
  Interrompida: {
    icon: 'fas fa-exclamation-circle',
    description: 'Interrompida',
    color: 'danger',
  },
  Expirada: {
    icon: 'fas fa-hourglass-end',
    description: 'Expirada',
    color: 'warning',
  },
  'Em Atendimento': {
    icon: 'fas fa-thumbs-up',
    description: 'Em Atendimento',
    color: 'primary',
  },
  Aceita: {
    icon: 'fas fa-check',
    color: 'primary',
  },
  Recusada: {
    description: 'Recusada',
    color: 'danger',
  },
  EnviarNotificacao: {
    icon: 'fas fa-bullhorn',
    description: '',
    color: 'warning',
  },
};

export const AGUARDANDOPROPOSTA = 'Aguardando Proposta';
export const AGUARDANDOACEITE = 'Aguardando Aceite';
export const EMATENDIMENTO = 'Em Atendimento';
export const EXPIRADA = 'Expirada';
export const INTERROMPIDA = 'Interrompida';
export const AGENDADA = `Agendada`;

export const SOLICITACOES = 'SOLICITACOES';
export const PENDING_SOLICITATIONS = 'PENDING_SOLICITATIONS';
export const IN_PROGRESS_SOLICITATIONS = 'IN_PROGRESS_SOLICITATIONS';
export const CONCLUDED_SOLICITATIONS = 'CONCLUDED_SOLICITATIONS';
export const SCHEDULED_SOLICITATIONS = 'SCHEDULED_SOLICITATIONS';

export const TIMER_MONITORING = 15000;
export const TIMER_CHECK_RESPONSE = 15000;
export const TIMER_LIST = 5000;

export const MIN_CHARS_TO_CALL_SEARCH = 3;

export const DAF_DEALERSHIP = {
  '2491002504561562893': 'DAF Austral | Caxias do Sul - RS',
  '2491013835507373328': 'DAF Austral | Portão - RS',
  '2491019241193477394': 'DAF Avanthy | Belém - PA',
  '2542459945966638689': 'DAF Avanthy | Marabá - PA',
  '2547353632425121405': 'DAF Barigui | Cascavel - PR',
  '2491007146532017423': 'DAF Barigui | Chapecó - SC',
  '2491029665985074453': 'DAF Barigui | Itajaí - SC',
  '2491025915497157907': 'DAF Barigui | São José dos Pinhais - PR',
  '2825906392819307592': 'DAF Barigui | Tubarão - SC',
  '2890347428639151559': 'DAF Caiobá Trucks - Dourados MS',
  '2491058601364296983': 'DAF Caiobá Trucks | Campo Grande - MS',
  '2491029215718151444': 'DAF Caminho | Ribeirão Preto - SP',
  '2491064789464257816': 'DAF Caminho | São José do Rio Preto - SP',
  '2491068435656611097': 'DAF Caramori | Cuiabá - MT',
  '2491073827459568922': 'DAF Caramori | Rondonópolis - MT',
  '2491077548243424539': 'DAF Caramori | Sinop - MT',
  '2572184999310661427': 'DAF Du Gregório | Brasília - DF',
  '2491081319879021852': 'DAF Du Gregório | Luís Eduardo Magalhães - BA',
  '2491792570825315633': 'DAF Du Gregório | Paraíso do Tocantins - TO',
  '2491500350335358237': 'DAF Eldorado | Eldorado do Sul - RS',
  '2551717596542015135': 'DAF Eldorado | Passo Fundo - RS',
  '2491530264061154594': 'DAF MacPonta | Boituva - SP',
  '2491571362007618851': 'DAF MacPonta | Marialva - PR',
  '2491574894567888164': 'DAF MacPonta | Ponta Grossa - PR',
  '2491576978776266021': 'DAF MacPonta | Sumaré - SP',
  '2667775435466609999': 'DAF Nissey | Vilhena - RO',
  '3073022661241083317': 'DAF Nissey | Manaus - AM',
  '2871469844782388575': 'DAF Nova Caminhões / Maceió AL',
  '2491580177226667302': 'DAF Nova | Jaboatão dos Guararapes - PE',
  '2491584091745424679': 'DAF Nova | Petrolina - PE',
  '2491586937329030440': 'DAF Sancar | Campanha - MG',
  '2491588779140191529': 'DAF Sancar | Muriaé - MG',
  '2491592743705384234': 'DAF Somafértil | Aparecida de Goiânia - GO',
  '2491629809902294315': 'DAF Somafértil | Rio Verde - GO',
  '2491637727808722220': 'DAF Somafértil | Uberlândia - MG',
  '2491652973256512813': 'DAF Via Trucks | Contagem - MG',
  '2491655738250761518': 'DAF Via Trucks | Guarulhos - SP',
  '2491657910304642351': 'DAF Via Trucks | São Bernardo do Campo - SP',
  '2491660170875114800': 'DAF Vitória | Feira de Santana - BA',
  '2491002633158923534': 'DAF Vitória | Viana - ES',
  '2491506236529317150': 'Fornecedora | Campina Grande - PB',
  '2491512151571698975': 'Fornecedora | Fortaleza - CE',
  '2491527630357009697': 'Fornecedora | Parnamirim - RN',
  '2491522565323887904': 'Fornecedora | Teresina - PI',
};
