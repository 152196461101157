import React from 'react';
import PropTypes from 'prop-types';
import './newAttendanceSolicitation.css';

function WarningAttendance({
    tipoServico,
    containerStyle
}) {
  if (tipoServico !== 'MECANICO') return null;

  return (
    <div style={containerStyle}>
      <p className="text-red">
        * Informar ao cliente sobre o tempo extra de uma a duas horas que o
        técnico tem para iniciar a corrida.
      </p>
      <p className="text-red">
        * Adicionar no campo 'tempo' a estimativa do técnico para sair para o
        atendimento (Ex: Se o técnico levará 1 hora para sair, então deve somar
        essa hora ao tempo já preenchido)
      </p>
    </div>
  );
}

WarningAttendance.propTypes = {
    tipoServico: PropTypes.string.isRequired,
    containerStyle: PropTypes.object,
}

export default WarningAttendance;